import Main from "../views/home";
import lazyLoading from "./lazy-loading";
import {_FindAllPage} from '@/api/selCar'
import {
    _GetUserInfo
} from '@/api/login/index'
export const initMenu = (router, store, to, form, next) => {
    if(to.name=="login"){
        localStorage.removeItem("tokenStr")
        return
    }
    if (store.state.routes.length > 0) {
        return;
    }
    _GetUserInfo().then(res=>{
        let fmtRoutes = formatRoutes(res.data.menuList)
        router.addRoutes(fmtRoutes)
        store.commit("initRoutes",fmtRoutes);
        store.commit("setCompanyId",res.data.map.companyId);

        window.localStorage.setItem(
            'userInfo', JSON.stringify(res.data.map))
    })
    // setTimeout(() => {
    //     let newRouters = [
    //         {
    //             classIcon: "iconfont icon-zhuzhuangtu",
    //             name: "数据管理与分析",
    //             url: '',
    //             component: "",
    //             children: [{
    //                 name: "数据管理",
    //                 component: "dataAnalysis/dataManagement",
    //                 url: "/dataManagement",
    //                 classIcon: "",

    //                 children: [

    //                 ]
    //             },
    //             {
    //                 name: "数据分析",
    //                 component: "dataAnalysis/dataAnalysis",
    //                 url: "/dataAnalysis",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             },

    //             {
    //                 name: "车辆历史回溯",
    //                 component: "dataAnalysis/historyBack",
    //                 url: "/historyBack",
    //                 classIcon: "el-icon-user-solid",
    //                 children: [{
    //                     name: "车辆历史回溯详情",
    //                     component: "dataAnalysis/historyBackDetail",
    //                     class: "",
    //                     children: [],
    //                     url: "/historyBackDetail"
    //                 }]
    //             },
    //             {
    //                 name: "车辆故障分析",
    //                 component: "dataAnalysis/faultAnalysis",
    //                 url: "/faultAnalysis",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             },
    //             {
    //                 name: "CAN数据采集",
    //                 component: "dataAnalysis/canAcquisition",
    //                 url: "/canAcquisition",
    //                 classIcon: "el-icon-user-solid",

    //             },
    //             {
    //                 name: "健康监测",
    //                 component: "dataAnalysis/healthMonitoring",
    //                 url: "/healthMonitoring",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             },
    //             // {
    //             //     name: "测试",
    //             //     component: "dataAnalysis/test",
    //             //     url: "/test",
    //             //     classIcon: "el-icon-user-solid",
    //             //     children: []
    //             // }
    //             ]
    //         },
    //         {
    //             classIcon: "iconfont icon-jiankong",
    //             name: "设备监控",
    //             url: '',
    //             component: "",
    //             children: [{
    //                 name: "车辆控制记录",
    //                 component: "equipmentMonitoring/vehControlRecord",
    //                 url: "/vehControlRecord",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             }
    //             ]
    //         },
    //         {
    //             classIcon: "iconfont icon-cheliang",
    //             name: "车辆注册管理",
    //             url: '',
    //             component: "",
    //             children: [{
    //                 name: "车辆注册管理",
    //                 component: "vehRegistration/vehRegistration",
    //                 url: "/vehRegistration",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             }
    //             ]
    //         },
    //         {
    //             classIcon: "iconfont icon-yunying",
    //             name: "园区运营",
    //             url: '',
    //             component: "",
    //             children: [{
    //                 name: "园区运营计划看板",
    //                 component: "parkOperation/planKanban",
    //                 url: "/planKanban",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             }
    //             ]
    //         },
    //         {
    //             classIcon: "iconfont icon-xitongguanli",
    //             name: "系统管理",
    //             url: '',
    //             component: "",
    //             children: [{
    //                 name: "账号管理",
    //                 component: "systemManagement/accountManage",
    //                 url: "/accountManage",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []

    //             },
    //             {
    //                 name: "角色管理",
    //                 component: "systemManagement/roleManage",
    //                 url: "/roleManage",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             },
    //             {
    //                 name: "菜单管理",
    //                 component: "systemManagement/menuManageXt",
    //                 url: "/menuManageXt",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             }
    //             ]
    //         },
    //         {
    //             classIcon: "iconfont icon-cankaoziliao",
    //             name: "参考系管理",
    //             url: '',
    //             component: "",
    //             children: [{
    //                 name: "平台管理",
    //                 component: "referenceSystem/platformManagement",
    //                 url: "/platformManagement",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             }
    //             ]
    //         },
    //         {
    //             classIcon: "iconfont icon-gaiicon-",
    //             name: "企业注册管理",
    //             url: '',
    //             component: "",
    //             children: [{
    //                 name: "企业注册",
    //                 component: "enterRegistration/enterpriseRegister",
    //                 url: "/enterpriseRegister",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []

    //             },
    //             {
    //                 name: "申请企业",
    //                 component: "enterRegistration/appliEnterprise",
    //                 url: "/appliEnterprise",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             }
    //             ]
    //         },
    //         {
    //             classIcon: "iconfont icon-jiagou",
    //             name: "SASS端管理",
    //             url: '',
    //             component: "",
    //             children: [
    //                 {
    //                     name: "运维管理",
    //                     component: "sassManagement/mochaITOM",
    //                     url: "/mochaITOM",
    //                     classIcon: "el-icon-user-solid",
    //                     children: []

    //                 },
    //                 {
    //                     name: "菜单管理",
    //                     component: "sassManagement/menuManageSass",
    //                     url: "/menuManageSass",
    //                     classIcon: "el-icon-user-solid",
    //                     children: []
    //                 },
    //                 {
    //                     name: "APP账号管理",
    //                     component: "sassManagement/accountManageApp",
    //                     url: "/accountManageApp",
    //                     classIcon: "el-icon-user-solid",
    //                     children: []
    //                 },
    //                 {
    //                     name: "车辆类型管理",
    //                     component: "sassManagement/vehicleType",
    //                     url: "/vehicleType",
    //                     classIcon: "el-icon-user-solid",
    //                     children: []
    //                 }
    //             ]
    //         },
    //         {
    //             classIcon: "iconfont icon-shouji",
    //             name: "移动端管理",
    //             url: '',
    //             component: "",
    //             children: [{
    //                 name: "移动端管理",
    //                 component: "mobileManagement/mobileManagement",
    //                 url: "/mobileManagement",
    //                 classIcon: "el-icon-user-solid",
    //                 children: []
    //             }
    //             ]
    //         },
    //         // {
    //         //     classIcon: "el-icon-office-building",
    //         //     name: "账号中心",
    //         //     url: 'home/home',
    //         //     path: "/home",
    //         //     children: [{
    //         //         name: "账号中心",
    //         //         url: "home/accCenter",
    //         //         path: "/accCenter",
    //         //         classIcon: "el-icon-user-solid",
    //         //         children: []
    //         //     }
    //         //     ]
    //         // },
    //     ]

    //     //格式化
    //     let fmtRoutes = formatRoutes(newRouters)
    //     console.log(fmtRoutes);
    //     //添加到route
    //     // fmtRoutes.forEach(e => {
    //     //     router.addRoute(e)
    //     // })
    //     router.addRoutes(fmtRoutes)

    //     //存到vuex里面
    //     // store.commit('initRoutes', fmtRoutes);
    //     store.dispatch("setRoutes",fmtRoutes);
    //     // if (form.name == "login") {
    //     //     router.replace(store.state.routes[0].children[0].path)
    //     // }

    // }, 1);

}

export const formatRoutes = (routes) => {
    let fmtRoutes = []
    routes.forEach(router => {
        let { url, menuName, icon, children, level,code, redirect,isMenu,id } = router
        if (children && children.length > 0) {
            children = formatRoutes(children)
        }
        let fmRouter = {}
        if (router.code==""||router.code==null) {
            fmRouter = {
               id, hidden:isMenu, path: url, redirect,level,name: menuName, children, icon, component: Main
            }
        } else {
            fmRouter = {
              id,  hidden:isMenu, path: url,name:menuName, level,children, icon,component(resolve) {
                    require(['../views/' + router.code + '.vue'], resolve)
                }
            }
        }

        fmtRoutes.push(fmRouter)

        // let { url, name, classIcon, children,component } = router
        // if (children && children.length > 0) {
        //     children = formatRoutes(children)
        // }
        // let fmRouter = {}
        // if(router.component==""){
        //     fmRouter = {
        //         path:url, name, children: children, classIcon, component:Main
        //     }
        // }else{
        //     fmRouter = {
        //         path:url, name, children: children, classIcon, component(resolve) {
        //             require(['../views/' + component + '.vue'], resolve)
        //         }
        //     }
        // }

        // fmtRoutes.push(fmRouter)

    })
    return fmtRoutes
}

