<template>
  <div class="homeWrap">
    <el-container class="container">
      <el-collapse-transition>
        <el-aside :width="!isCollapse ? '200px' : '60px'">
          <img src="../../assets/images/asideImg.png" v-show="!isCollapse" />
          <img
            src="../../assets/images/centerLogo.jpg"
            v-show="isCollapse"
            class="narrow"
          />
          <el-scrollbar style="width: 100%">
            <el-menu
              @select="handleSelect"
              router
              unique-opened
              :collapse="isCollapse"
              @open="handleOpen"
              @close="handleClose"
              :default-active="defaultActive"
            >
              <el-submenu
                :index="index + ''"
                v-for="(item, index) in routes"
                :key="index"
              >
                <template slot="title"
                  ><i :class="[item.icon]"></i>
                  <span class="iconRight" v-show="!isCollapse">
                    {{ item.name }}
                  </span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                    :index="children.path"
                    v-for="(children, indexxj) in item.children"
                    :key="indexxj"
                    v-if="isShow(children)"
                  >
                    <img
                      v-show="currentDicShow(children)"
                      src="@/assets/images/currentIndication.png"
                      alt=""
                      class="currentImg"
                    />
                    <span class="iconRight"> {{ children.name }} </span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>

            <!-- <el-menu router :collapse="isCollapse"
                            :default-active="$route.path">
                            <NavMenu :navMenus="routes"></NavMenu>
                        </el-menu> -->
          </el-scrollbar>
        </el-aside>
      </el-collapse-transition>
      <el-container>
        <el-header class="homeHeader">
          <div class="homeTop">
            <div class="versionNumber">
              <img
                src="../../assets/images/headZd.png"
                class="headZd"
                @click="fold"
              />
            </div>
            <div class="headDrop">
              <!-- <img src="../../assets/images/headSearch.png" class="headZd" /> -->
              <!-- <img src="../../assets/images/headTz.png" class="headZd" /> -->
              <img
                src="../../assets/images/headFd.png"
                class="headZd"
                @click="full"
              />
              <!-- <img src="../../assets/images/headWz.png" class="headZd" /> -->
              <div class="headport">
                <img src="../../assets/images/portrait.png" />
              </div>
              <el-dropdown @command="handleCommand">
                <span>
                  {{ userName }}
                  <!-- {{user.name}}<i> </i> -->
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="accCenter"
                    >账号中心</el-dropdown-item
                  >
                  <el-dropdown-item command="signOut"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <img src="../../assets/images/headSz.png" class="headZd" /> -->
            </div>
          </div>
          <div class="homeBtm">
            <h2>{{ this.$router.currentRoute.name }}</h2>
            <div class="breadcrumbBox">
              <img src="@/assets/images/house.png" alt="" />
              <el-breadcrumb
                separator-class="el-icon-arrow-right"
                v-if="detailObj.meta"
              >
              </el-breadcrumb>
              <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
                <el-breadcrumb-item>{{
                  this.$router.currentRoute.matched[0].name
                }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{
                  this.$router.currentRoute.name
                }}</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import { _LoginOut } from '@/api/login/index'
import NavMenu from '@/components/NavMenu.vue'
export default {
  components: {
    NavMenu
  },
  data() {
    return {
      user: JSON.parse(sessionStorage.getItem('user')),
      isCollapse: false,
      userName: '',
      defaultActive: this.$route.path,
      triangle: '',
      detailObj: {},
      env: process.env
    }
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    this.userName = userInfo.user_name
    // this.activeDefault()
  },

  methods: {
    handlePush() {},
    getDefaultActive(index) {
      return index ? index : this.$route.path
    },
    handleSelect(index) {},
    // activeDefault() {
    //     return this.$router.currentRoute.path

    // },
    menuClick(index) {
      this.$router.push(index)
    },
    fold() {
      this.isCollapse = !this.isCollapse
    },
    currentDicShow(item, item2) {
      if (item.path == this.$router.currentRoute.path) {
        return true
      } else if (item.path == this.triangle) {
        return true
      } else {
        return false
      }
    },
    //全屏
    full() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: '您的浏览器不允许全屏',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle()
    },
    handleCommand(command) {
      if (command == 'accCenter') {
        this.$router.push('/accCenter')
      } else {
        this.loginOut()
      }
    },
    //退出发请求给后台接收
    loginOut() {
      let id = JSON.parse(localStorage.getItem('userInfo')).id
      console.log('点击退出')
      console.log(id)
      _LoginOut({ id: id }).then(res => {
        console.log(res)
        localStorage.removeItem('tokenStr')
        this.$store.commit('initRoutes', [])
        this.$router.push('/login')
      })
    },

    isShow(item) {
      return item.isMenu == 1 ? false : true
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    getTreeItem(path) {
      let result = null
      this.$store.state.routes.map(item => {
        if (item.path == path) {
          result = item // 结果赋值
        } else {
          if (item.children) {
            getTreeItem(item.children, path)
          }
        }
      })
      return result
    }
  },
  computed: {
    routes() {
      return this.$store.state.routes
    }
  },
  watch: {
    $route(to, from) {
      if (to.meta.parentPath) {
        this.defaultActive = to.meta.parentPath
        this.triangle = to.meta.parentPath
        this.detailObj = to.meta
      } else {
        this.triangle = ''
        this.detailObj = {}
      }
      // to 和 from 都是 路由信息对象
    }
  }
}
</script>

<style lang="less">
.el-submenu .el-menu-item:hover {
  // background: rgb(84, 92, 100) !important;
  background: rgba(22, 131, 217, 0.06) !important;
}

.el-submenu.is-active .el-submenu__title {
  color: #1683d9;
  font-weight: 600;

  i {
    color: #1683d9;
    font-weight: 600;
  }

  .el-submenu__icon-arrow {
    // transform: translateY(37%);
  }
}

.container {
  height: 100%;
  background: #f5f8fa;
}

.narrow {
  margin-left: 12px;
  margin-top: 12px;
}

// .el-menu-item.is-active {
//     background: rgba(22, 131, 217, 0.06) !important;
// }

.homeWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.el-main {
  padding: 0px 20px 0px;
}

.el-main::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.el-submenu.is-active .el-menu-item-group > ul {
  background-color: #f1f8fd;
}

.el-menu {
  border-right: 0px;

  .currentImg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  .el-menu-item.is-active {
    background-color: #f1f8fd;
    font-size: 13px;
    color: #333;
    font-weight: 600;
  }

  .iconRight {
    margin-left: 8px;
    font-size: 14px;
  }

  .el-menu-item {
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-header {
  padding: 0px;
}

.el-dropdown {
  color: white;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.versionNumber {
  display: flex;
  align-items: center;
  color: white;
}

.el-scrollbar__view > .el-menu {
  height: calc(100vh - 120px);
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

.el-menu::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.el-aside::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.nav .menu::-webkit-scrollbar-thumb {
  margin-right: 1px;
  background: hsla(0, 0%, 70.6%, 0.4);
  background: white;
  background-clip: padding-box;
  min-height: 30px;
  border-radius: 20px;
}

.el-aside {
  color: #333;
  background-color: #fff;
  transition: all 0.2s;
  overflow-y: hidden;

  .el-scrollbar__bar.is-horizontal {
    height: 0px;
  }

  // text-align: center;
}

.el-menu-item-group > .el-menu-item-group__title {
  padding: 0px !important;
}

.el-menu-item-group__title {
  padding-top: 0px;
}

.homeHeader {
  height: 170px !important;

  .homeTop {
    background-color: #1683d9;
    display: flex;
    height: 56px !important;
    padding: 0px 20px;
    font-size: 14px;
    align-items: center;
    justify-content: space-between;

    .headport {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      overflow: hidden;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headZd {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-right: 20px;
    }

    .headDrop {
      display: flex;
      align-items: center;
    }
  }

  .homeBtm {
    background: #f5f8fa;
    justify-content: center;
    flex-direction: column;
    padding: 0px 22px 22px;

    .breadcrumbBox {
      display: flex;
      align-items: center;
      font-size: 16px;

      .el-breadcrumb {
        > span:first-child {
          color: #999;
        }

        span {
          color: #666;
        }
      }
    }

    img {
      vertical-align: top;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
</style>
