import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import AmapVue from '@amap/amap-vue';
import axios from '@/until/api'
import { initMenu } from './until/menus'
import '@/assets/font-awesome/css/font-awesome.css'
import '@/assets/icon/iconfont.css'
import less from 'less'
import resetMessage from './until/msg';
import dataV from '@jiaminghi/data-view'
import VueCesium from 'vue-cesium'
Vue.use(elementUI).use(less).use(dataV).use(VueCesium,{
  cesiumPath: 'https://unpkg.com/cesium@latest/Build/Cesium/Cesium.js',
  accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlOTBkZWZlNi1mNmM5LTQ1NWYtOTA5ZC1mNTFkNWViNzIzY2IiLCJpZCI6NDIzOTgsImlhdCI6MTYxMTMwNzM4M30.evPzc45sswPDk2gX_aMt8oRRCEdLPtxN1hS9TTJEWNI'
})
Vue.prototype.$message = resetMessage;
Vue.config.productionTip = false
Vue.prototype.$http = axios
AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = '012752289e88ed9c14933d0f578aca32';
AmapVue.config.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];



window.console.warn = () => { }   //去除警告

let url = window?.urlConfig.baseUrl.slice(0,5) == 'https' ? 'https://portal.usharingtech.com' : 'http://portal.dev.usharingtech.com'

router.beforeEach((to, form, next) => {
  const obj = {
    token:window.localStorage.getItem('token'),
    tokenStr:window.localStorage.getItem('tokenStr')
  }
  if (window.localStorage.getItem('tokenStr')) {
    if(to.path==='/largeScreen'){
      let {href} = router.resolve({path:'/Screen'})
      window.open(href,"_blank")
    }
    else if (to.path === "/gis") {
      if(form.path!=='/login'){
        // window.open('http://localhost:3004/gis/#/main', JSON.stringify(obj))
        window.open(url + '/gis/#/nationwideView', JSON.stringify(obj))
      }
    }
    else if (to.path === "/monitor") {
      if(form.path!=='/login'){
        // window.open('http://localhost:3004/gis/#/monitor', JSON.stringify(obj))
        window.open( url + '/gis/#/monitor', JSON.stringify({...obj,type:'gis'}))
      }
    }else if (to.path === "/chunsun") {
      if(form.path!=='/login'){
        // window.open('http://localhost:3004/gis/#/main', JSON.stringify(obj))
        window.open( url + '/chunsun/#/nationwideView', JSON.stringify({...obj,type:'chunsun'}))
      }
    }
    else{
      initMenu(router, store, to, form, next)
      next()
    }
  } else {
    if (to.path == "/login") {
      localStorage.removeItem('tokenStr')
      next()
    } else {
      next({ path: '/login' })
    }
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
