import service from '../../until/api'
// 查询全部的车辆类型
export function _CarTypeAll(data) {
    return service({
      url: '/vehicle-auth/carType/findAll',
      method: 'GET',
      params: data 
    })
  }

  
// 查询全部的车辆运营类型
export function _FindAllCarOperationType(data) {
    return service({
      url: '/vehicle-auth/carOperationType/findAllCarOperationType',
      method: 'GET',
      params: data 
    })
  }

  //中心云--车辆注册管理---分配企业时查询所有企业
  export function _FindAllCompany(data) {
    return service({
      url: '/user-nacos/company/findAllCompany',
      method: 'GET',
      params: data 
    })
  }
 
  //中心云账号管理页面---查询所有角色
  export function _FindAllRole(data) {
    return service({
      url: '/user-nacos/role/findAll',
      method: 'GET',
      params: data 
    })
  }
  // 查询所有菜单
export function _FindAllPage(data) {
  return service({
      url: '/system-nacos/menu/getAllMenu',
      method: 'GET',
      params: data,
  })
}

//查询所有增值服务
export function _incrementsFindAll(data) {
  return service({
      url: '/vehicle-auth/increments/findAll',
      method: 'GET',
      params: data
  })
}

//查询全部业务服务包
export function _FindAllBusinessService(data) {
  return service({
      url: '/vehicle-auth/business/findAllBusinessService',
      method: 'GET',
      params: data
  })
}
//查询全部数据服务包
export function _FindAllDateService(data) {
  return service({
      url: '/vehicle-auth/business/findAllDataService',
      method: 'GET',
      params: data
  })
}

