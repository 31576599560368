import axios from 'axios'
import router from '../router'
import { Message } from 'element-ui'
import envAPIS from './envAPIS'

const urlConfig = window.urlConfig
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: urlConfig.url,
    // baseURL: 'http://192.168.1.107:9000/',
    // baseURL: 'http://xilei.natapp1.cc/',
    // 超时
    timeout: 20000
})


// Add a request interceptor
service.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (window.localStorage.getItem('tokenStr')) {
        config.headers.Authorization = "Bearer " + window.localStorage.getItem('tokenStr')
        config.headers['X-Token'] = "Basic " + window.localStorage.getItem('token')
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
service.interceptors.response.use(function (response) {
    // Do something with response data
    // if (response.status && response.status == 200) {
    //     if (response.data.code == 500 || response.data.code == 401 || response.data.code == 403) {
    //         Message.error(response.data.message)
    //         return;
    //     }
    //     // if (response.data.message) {
    //     //     Message.success(response.data.message)
    //     // }
    // }
    // if(response.data.success==true){
    // }else{
    //     Message.error(response.data.message)
    //     return;
    // }
    if((!response.data.success) && response.data.hasOwnProperty("success")){
        Message.error(response.data.message)
    }
    return response.data;
}, function (error) {
    // Do something with response error
    if (error.response.status == 504 || error.response.status == 404) {
        Message.error('服务器异常')
    } else if (error.response.status == 403) {
        Message.error('权限不足，请联系管理员')
    } else if (error.response.status == 401) {
        Message.closeAll()
        Message.error('尚未登录，请登录')
        localStorage.removeItem("tokenStr");
        let { href } = router.resolve({ path: '/login' });
        window.location.href = href;
    } else {
        Message.error(error)
    }
    return;
});

export default service
