import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/login',
    name: 'login',
    // redirect:"/login",
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/login/login.vue')
  },
  {
    name: '大屏',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/dataAnalysis/largeScreen.vue'
      ),
    path: '/Screen'
  },
  {
    name: '地图',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/dataAnalysis/charts/map1.vue'
      ),
    path: '/CsiumMap'
  },
  {
    path: '/',
    name: '主页',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/home/home.vue'),
    redirect: to => {
      if (store.state.routes.length > 1) {
        return store.state.routes[0].children[0].path
      } else {
        localStorage.removeItem('tokenStr')
        return '/login'
      }
      console.log(store.state.routes)
    },
    children: [
      {
        name: '账号中心',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/home/accCenter.vue'),
        path: '/accCenter'
      },
      {
        name: '车辆历史回溯详情',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/dataAnalysis/historyBackDetail.vue'
          ),
        path: '/historyBackDetail'
      },
      {
        name: '车辆数据采集',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/dataAnalysis/vehDataAcquisition.vue'
          ),
        path: '/vehDataAcquisition'
      },
      {
        name: '数据分析',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/dataAnalysis/healthAnalysis.vue'
          ),
        path: '/healthAnalysis'
      },
      {
        name: '查看详情',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/dataAnalysis/dataManagementDetail.vue'
          ),
        path: '/dataManagementDetail',
        meta: {
          parentPath: '/dataManagement',
          parentName: '数据管理'
        }
      },
      {
        name: '查看详情',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/dataAnalysis/dataManagementDetailDetail.vue'
          ),
        path: '/dataManagementDetailDetail'
      },
      {
        name: '紧急制动-详细数据',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/dataAnalysis/checkAlarmDetail.vue'
          ),
        path: '/checkAlarmDetail'
      },
      {
        name: '我要反馈',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/userFeedback/feedback.vue'
          ),
        path: '/feedback'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash', //his 打包有问题
  base: process.env.BASE_URL,
  routes
})

export default router
