// import axios from '../until/api.js'

// export const login = (params ) => {
//     return axios.post( '/user/login' , params )
// }

import service from '../../until/api'

/**
 * 1.接口名称前带下划线
 * 2.url：代表请求的url
 * 3.method：代表请求的方式，请用大写，'GET', 'POST', 'PUT', 'DELETE'
 * 4.data：代表请求的参数
 *    参数以对象在body传递请使用data: data;
 *    参数以拼接到url方式传递请使用params: data
 *    注意！！！data和params两种参数只能选一种
 * 
 * 
 */
export function _Login(data) {
  console.log('data', data)
  return service({
    url: '/df-oauth/auth/userlogin',
    method: 'POST',
    data: data, // 参数以对象在传递请使用‘data: data’（多用于post请求）
    // params: data // 参数拼接在url传递请使用‘params: data’ （多用于get请求）
    headers: {
      "Authorization": "Basic " + btoa("XcWebApp:XcWebApp")
      },
  })
}
//获取验证码
export function _GetCode(data) {
  return service({
    url: '/df-oauth/auth/getCode',
    method: 'GET',
    params: data ,
    headers: {
      "Authorization": "Basic " + btoa("XcWebApp:XcWebApp")
      },
  })
}


//获取登录成功后的token

export function _GetJwtToken(data) {
  return service({
    url: '/df-oauth/auth/getJwtToken',
    method: 'GET',
    params: data ,
    headers: {
      "Authorization": "Basic " + btoa("XcWebApp:XcWebApp")
      },
  })
}


// /获取用户基本信息
export function _GetUserInfo(data) {
  return service({
    url: '/user-nacos/login/getUserInfo',
    method: 'GET',
    params: data ,
  })
}


// /退出登录 向后台发送东西
export function _LoginOut(data) {
  return service({
    url: '/user-nacos/user/userLogout',
    method: 'GET',
    params: data, 
    
  })
}


// 获取用户账号是否激活
export function _GetAccountIfActive(params) {
  return service({
    url: '/df-oauth/auth/ifActive',
    method: 'GET',
    params: params
  })
}

